import { Link } from 'gatsby';
import React from 'react';

import 'styles/pages/about/contact.scss';

const Contact = () => {
  return (
    <div className='contact-about'>
      <div className='contact-image'>
        <div className='contact-container'>
          <h1 className='title'>Where to find us.</h1>
          <p className='desc'>
            We have air-side access to Winnipeg International Airport and our
            hangar is located at St. Andrews Airport. We are open to clients
            nationwide.
          </p>
          <Link to='/contact' className='contact-link'>
            More Details
          </Link>
          <a href='tel:204-803-5473' className='contact-direct'>
            <p className='contact-type'>Call Us</p>
            <p className='contact-info'>(204) 803-5473</p>
          </a>
          <a
            href='mailto:josh.wilson@mobileairservice.com'
            className='contact-direct'
          >
            <p className='contact-type'>Email Us</p>
            <p className='contact-info'>josh.wilson@mobileairservice.com</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
