import * as React from 'react';

import Seo from '../components/global/seo';
import Layout from '../components/global/layout';
import Contact from '../components/about/contact';
import About from '../components/about/about';

const AboutPage = () => {
  return (
    <Layout>
      <About />
      <Contact />
    </Layout>
  );
};

export const Head = () => <Seo title='About' />;

export default AboutPage;
