import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import SliderImage1 from 'images/Airbus-Logo.png';
import SliderImage2 from 'images/Beechcraft_Logo.png';
import SliderImage3 from 'images/Bell_logo.png';
import SliderImage4 from 'images/De_Havilland_Aircraft_logo.png';
import SliderImage5 from 'images/Cessna-Logo.png';
import SliderImage6 from 'images/Piper_logo.png';
import SliderImage7 from 'images/lake_aircraft_logo.png';

import 'styles/pages/about/about.scss';

const About = () => {
  return (
    <div className='about-page'>
      <div className='about-title'>
        <h1 className='title'>About Us</h1>
        <p className='desc'>
          Mobile Air Service was founded in 2010 and approved for work on
          commercial aircraft in 2012. We offer a wide range of skills to
          maintain your aircraft. Here at Mobile Air Service, we believe in a
          product that goes beyond just the maintenance of your aircraft. We
          believe in relationship. Whether your dream is to own your very own
          airplane and fly around the sky on a beautiful summers day or to use
          your airplane to service the remote communities of the north. Let us
          help you get there.
        </p>
        <p className='desc'>
          We believe that maintenance is the proactive way to ensure these
          machines always reach their destination safely.
        </p>
        <p className='desc'>
          We offer aircraft maintenance and repair services that are second to
          none.  We work with a wide range of operators in a variety of roles.
        </p>
      </div>
      <div className='about-airplanes'>
        <div className='about-airplanes-content'>
          <article className='about-airplanes-block block--1'>
            <div className='block-content'>
              <h3 className='title'>What we work on</h3>
              <p className='desc'>
                We have experience in maintaining the Beechcraft King Air series
                aircraft including the 200, B200, 350 and 1900.  We also have
                extensive knowledge on the Piper PA31 series aircraft, DHC 2,
                DHC 3, Cessna 100 series, Cessna 441, Lake Amphibian, Piper
                Cherokee and many other general aviation aircraft.
              </p>
            </div>
            <div className='block-image'>
              <LazyLoadImage
                src='https://images.unsplash.com/photo-1597464524506-cb28023a9adf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
                alt='Aircraft Image'
              />
            </div>
          </article>
          <article className='about-airplanes-block block--2'>
            <div className='block-content'>
              <h3 className='title'>AMO Approved</h3>
              <p className='desc'>
                As an Approved Maintenance Organization by Transport Canada we
                believe in the benefits of maintaining the machines you operate.
                The goals of Transport Canada is to ensure the public is offered
                a safe environment to travel. We have been approved to pursue
                this goal alongside Transport Canada.
              </p>
            </div>
            <div className='block-image'>
              <LazyLoadImage
                src='https://images.unsplash.com/photo-1609558278290-5bd1e72720d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
                alt='Aircraft Image'
              />
            </div>
          </article>
          <h2 className='ratings-title'>Transport Canada ratings</h2>
          <div className='ratings'>
            <article className='about-airplanes-block'>
              <div>
                <h3 className='title'>"Aircraft Category" ratings</h3>
                <p className='desc'>
                  Transport Canada has entrusted Mobile Air Service with the
                  following "Aircraft Category" ratings.
                </p>
                <ul>
                  <li>Airbus Helicopters, AS350 series helicopters</li>
                  <li>Beech 1900 series airplanes</li>
                  <li>Beech King Air 300 series airplanes</li>
                  <li>Bell 206 series helicopters</li>
                  <li>
                    Piston powered airplanes with a MTOW of 5700 kg and below
                  </li>
                  <li>
                    Turbo-propeller powered airplanes with a MTOW of 5700 kg and
                    below
                  </li>
                </ul>
              </div>
            </article>
            <article className='about-airplanes-block'>
              <div>
                <h3 className='title'>"Structure Category" rating</h3>
                <p className='desc'>
                  Transport Canada has also entrusted Mobile Air Service with
                  the following "Structure Category" rating.
                </p>
                <ul>
                  <li>Sheet metal structure</li>
                </ul>
              </div>
            </article>
          </div>
        </div>
        <div className='slider'>
          <div className='slide-track'>
            <div className='slide'>
              <img
                src={SliderImage1}
                height='100'
                width='250'
                alt='Airbus Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage2}
                height='100'
                width='250'
                alt='Beechcraft Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage3}
                height='100'
                width='250'
                alt='Bell Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage4}
                height='100'
                width='250'
                alt='DeHavilland Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage5}
                height='100'
                width='250'
                alt='Cessna Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage6}
                height='100'
                width='250'
                alt='Piper Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage7}
                height='100'
                width='250'
                alt='Lake Aircraft Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage1}
                height='100'
                width='250'
                alt='Airbus Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage2}
                height='100'
                width='250'
                alt='Beechcraft Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage3}
                height='100'
                width='250'
                alt='Bell Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage4}
                height='100'
                width='250'
                alt='DeHavilland Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage5}
                height='100'
                width='250'
                alt='Cessna Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage6}
                height='100'
                width='250'
                alt='Piper Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage7}
                height='100'
                width='250'
                alt='Lake Aircraft Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage1}
                height='100'
                width='250'
                alt='Airbus Logo'
              />
            </div>
            <div className='slide'>
              <img
                src={SliderImage2}
                height='100'
                width='250'
                alt='Beechcraft Logo'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
